import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import './index.css';
import Main from './Routes/Main';
import ServicesRoutes from './Routes/Services';
import reportWebVitals from './reportWebVitals';
interface Props {
  children: React.ReactElement
}

const Wrapper = ({ children }: Props) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}



ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Wrapper>
        <Main />
      </Wrapper>
      <Wrapper>
        <ServicesRoutes />
      </Wrapper>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
