import React, { useEffect } from 'react';
import { CartaContainer } from '../../Custom/Hero/HeroContainers';
import { HeroImg, Hero } from '../../Custom/Hero/HeroContent';
import proteccionCivil from '../../../Imgs/Servicios/capacitaciones/proteccion-civil.png';
import montacargas from '../../../Imgs/Servicios/capacitaciones/montacargas.jpg';
import materialPeligroso from '../../../Imgs/Servicios/capacitaciones/material-peligroso.jpg';

const Training = () => {
    useEffect(() => {
        const urlHash = window.location.hash;
        if (urlHash.length) {
            const element = document.getElementById(urlHash.substring(1));
            if (element) {
                element.scrollIntoView();
            }
        }
    }, []);
    return (
        <>
            <div className="m-2 row p-4 pb-2 pt-lg-8 align-items-center rounded-5 border shadow-lg">
                <CartaContainer id='protection'>
                    <Hero title={'PROTECCIÓN CIVIL'}>
                        Agente capacitador externo con registro ante la STPS:
                        <ul>
                            <li>Brigadas contra incendios.</li>
                            <li>Brigadas de evacuación.</li>
                            <li>Brigradas de busqueda y rescate.</li>
                            <li>Brigadas de primeros auxilios.</li>
                        </ul>
                    </Hero>
                    <HeroImg imagen={proteccionCivil} alt={'PROTECCIÓN CIVIL'} rounded />
                </CartaContainer>

                <CartaContainer id='montacargas' >
                    <HeroImg imagen={montacargas} alt={'USO DE MONTACARGAS'} rounded />
                    <Hero title={'USO DE MONTACARGAS'}>
                        Agente capacitador externo con registro ante la STPS:
                        <ul>
                            <li>Materia de Uso y manejo de montacargas.</li>
                        </ul>
                    </Hero>
                </CartaContainer>

                <CartaContainer id='waste' >
                    <Hero title={'MANEJO DE MATERIAL PELIGROSO'}>
                        Agente capacitador externo con registro ante la STPS:
                        <ul>
                            <li>Capacitación en materia de residuos peligrosos y manejo de sustancias químicas.</li>
                        </ul>
                    </Hero>
                    <HeroImg imagen={materialPeligroso} alt={'MANEJO DE MATERIAL PELIGROSO'} rounded />
                </CartaContainer>
            </div>
        </>
    );
}
export default Training;