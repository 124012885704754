import React from 'react';
import Card from '../../Custom/Card/Card';
import { useNavigate } from "react-router-dom";
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './Styles.css';

import analisis from '../../../Imgs/Home/Carrusel/analisis-riesgo1.jpeg';
import estudiosSeguridad from '../../../Imgs/Home/Carrusel/estudios-seguridad.jpeg';
import logos from '../../../Imgs/Home/Carrusel/logos.png';
import seguimiento from '../../../Imgs/Home/Carrusel/segumiento.jpeg';

const Servicios = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='services-container mx-2'>
                <div className='text-container'>
                    <h1 className="display-5 fw-bold lh-1 mb-3">SERVICIOS</h1>
                </div>
                <div className='swiper-container'>
                    <Swiper
                        modules={[Pagination, Navigation]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        loop={true}
                        pagination={{ clickable: true, dynamicBullets: true }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 50,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                            1200: {
                                slidesPerView: 4,
                                spaceBetween: 50,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <Card onClick={() => navigate("/services/services#analysis")} imagen={analisis} title='ANÁLISIS DE RIESGO' parrafo='Identificamos los potenciales peligros que pudiesen ocurrir con el objetivo de implantar medidas de prevención y control para su empresa.' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card onClick={() => navigate("/services/services#audit")} imagen='https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(31).jpg' title='AUDITORÍA' parrafo='Auditorías y/o inspecciones para la identificación de posibles fallas y oportunidades de mejora.' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card onClick={() => navigate("/services/studies")} imagen={estudiosSeguridad} title='ESTUDIOS DE SEGURIDAD Y MEDIO AMBIENTE ' parrafo='Estudios acreditados requeridos por la secretaría para cumplimiento con las obligaciones del patrón en materia de salud, seguridad y medio ambiente.' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card onClick={() => navigate("/services/services#control")} imagen={seguimiento} title='SEGUIMIENTO Y CONTROL' parrafo='Contestación a Actas de Inspección, SDUE, Protección Civil, PROFEPA, JMAS etc. Elaboración de expedientes para dar cumplimiento a las Normas Oficiales Mexicanas de la STPS.' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card onClick={() => navigate("/services/services#contingencies")} imagen='https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(33).jpg' title='PLAN DE CONTINGENCIAS' parrafo='Preparación de una instalación u organización para que esta pueda responder de manera efectiva en caso de una emergencia.' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card onClick={() => navigate("/paperwork")} imagen={logos} title='TRAMITES' parrafo='Contamos con varios servicios para la gestión de tramites y/o permisos y seguimientos de los mismos a nivel municipal, estatal o federal.' />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    );
}
export default Servicios;