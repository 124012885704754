import React, { useEffect } from 'react';
import { CartaContainer } from '../../Custom/Hero/HeroContainers';
import { HeroImg, Hero } from '../../Custom/Hero/HeroContent';
import analisis from '../../../Imgs/Servicios/servicios/analisis-de-riesgo.jpg';
import auditoria from '../../../Imgs/Servicios/servicios/auditoria.jpg';
import seguimiento from '../../../Imgs/Servicios/servicios/Seguimiento.png';
import hermeticidad from '../../../Imgs/Servicios/servicios/hermeticidad.jpg';
import inspeccion from '../../../Imgs/Servicios/servicios/inspeccion.png';


const Services = () => {
    useEffect(() => {
        const urlHash = window.location.hash;
        if (urlHash.length) {
            const element = document.getElementById(urlHash.substring(1));
            if (element) {
                element.scrollIntoView();
            }
        }
    }, []);
    return (
        <>
            <div className="m-2 row p-4 pb-2 pt-lg-8 align-items-center rounded-5 border shadow-lg">
                <CartaContainer id='analysis'>
                    <Hero title={'ANÁLISIS DE RIESGOS'}>
                        <ul>
                            <li>Instalaciones, identificación de actividades altamente riesgosas:
                                <ul>
                                    <li>Derrames</li>
                                    <li>Explosiones</li>
                                    <li>Incendios</li>
                                    <li>Fugas</li>
                                </ul>
                            </li>
                            <li>Modelación de contaminantes.</li>
                            <li>Determinación de riesgo de incendio.</li>
                            <li>Por maquinaria y equipo.</li>
                            <li>Riesgo químico.</li>
                            <li>Soldadura y corte.</li>
                            <li>Espacios confinados.</li>
                        </ul>
                    </Hero>
                    <HeroImg imagen={analisis} alt={'ANÁLISIS DE RIESGOS'} rounded />
                </CartaContainer>

                <CartaContainer id='audit' >
                    <HeroImg imagen={auditoria} alt={'AUDITORÍA?'} rounded />
                    <Hero title={'AUDITORÍA'}>
                        Auditorías y/o inspecciones para la identificación de posibles fallas y oportunidades de mejora en materia:
                        <ul>
                            <li>Ambiental.</li>
                            <li>Seguridad sanitaria.</li>
                            <li>Siniestros.</li>
                        </ul>
                    </Hero>
                </CartaContainer>

                <CartaContainer id='control' >
                    <Hero title={'SEGUIMIENTO Y CONTROL'}>
                        <ul>
                            <li>Contestación a Actas de Inspección, SDUE, Protección Civil, PROFEPA, JMAS etc.
                                <ul>
                                    <li>Elaboración de documento, ingreso y seguimiento.(En caso de generar un acuerdo administrativo debe analizarse para
                                        posible cotización).</li>
                                </ul>
                            </li>
                            <li>Elaboración de expedientes para dar cumplimiento a las Normas Oficiales Mexicanas de la STPS.
                                <ul>
                                    <li>Elaboración de expedientes para cumplimiento de obligaciones del patrón. Ajustable a sus necesidades o numerales en
                                        específico.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Hero>
                    <HeroImg imagen={seguimiento} alt={'SEGUIMIENTO Y CONTROL'} rounded />
                </CartaContainer>

                <CartaContainer id='contingencies'>
                    <HeroImg imagen={hermeticidad} alt={'PRUEBA DE HERMETICIDAD'} rounded />
                    <Hero title={'PRUEBA DE HERMETICIDAD'}>
                        Prueba de hermeticidad a la instalación de aprovechamiento de gas natural.
                    </Hero>
                </CartaContainer>

                <CartaContainer >
                    <Hero title={'INSPECCIÓN DE INSTALACIONES'}>
                        Inspección de instalaciones eléctricas con termografía infrarroja.
                    </Hero>
                    <HeroImg imagen={inspeccion} alt={'INSPECCIÓN DE INSTALACIONES'} rounded />
                </CartaContainer>
            </div>
        </>
    );
}
export default Services;