import React from 'react';
import JumbotronMain from '../../../Custom/Jumbotron/Jumbotron-main';
import JumbotronContainer from '../../../Custom/Jumbotron/JumbotronContainer';
import { CustomJumbotron } from '../../../Custom/Jumbotron/JumbotronContent';
import quimicos from '../../../../Imgs/Servicios/servicios/Estudios/agentes quimicos.jpg';
import termicas from '../../../../Imgs/Servicios/servicios/Estudios/termicas.jpg';
import ruido from '../../../../Imgs/Servicios/servicios/Estudios/ruido-laboral.jpg';
import calderas from '../../../../Imgs/Servicios/servicios/Estudios/calderas.jpg';
import iluminacion from '../../../../Imgs/Servicios/servicios/Estudios/iluminacion.jpg';
import vibracion from '../../../../Imgs/Servicios/servicios/Estudios/vibracion.jpg';
import ruidoPerimetral from '../../../../Imgs/Servicios/servicios/Estudios/ruido-perimetral.jpg';
import residuos from '../../../../Imgs/Servicios/servicios/Estudios/residuos.jpg';
import emisiones from '../../../../Imgs/Servicios/servicios/Estudios/emisiones.jpg';
import hidrocarburos from '../../../../Imgs/Servicios/servicios/Estudios/estudio-hidrocarburos.jpg';
import jmas from '../../../../Imgs/Servicios/servicios/Estudios/jmas.png';
import aguaProcesos from '../../../../Imgs/Servicios/servicios/Estudios/agua-en-procesos.jpg';
import aguaReuso from '../../../../Imgs/Servicios/servicios/Estudios/agua-reuso.jpg';

const Studies = () => {
    return (
        <>
            <main>
                <JumbotronMain title='ESTUDIOS DE SALUD Y SEGURIDAD EN EL TRABAJO' color={"my-bg-yellow"} textColor={"text-white"}>
                    <p>Estudios acreditados requeridos por la secretaría para cumplimiento con las obligaciones del patrón en materia de
                        salud, seguridad y medio ambiente.</p>
                    <p>Complementan las condiciones mínimas necesarias para la prevención de riesgos de trabajo. Se destinan a la atención
                        de factores de riesgo, a los que pueden estar expuestos los trabajadores.</p>
                </JumbotronMain>
                <JumbotronContainer>
                    <CustomJumbotron title='AGENTES QUÍMICOS CONTAMINANTES' color={"my-bg-green"} textColor={"text-white"} imagen={quimicos}>
                        Reconocimiento y evaluación de agentes químicos contaminantes del ambiente laboral NOM-010-STPS-2014.
                    </CustomJumbotron>
                    <CustomJumbotron title='CONDICIONES TÉRMICAS' color={"bg-dark"} textColor={"text-white"} imagen={termicas}>
                        Estudio de Condiciones térmicas elevadas o abatidas NOM-015-STPS-2001.
                    </CustomJumbotron>
                    <CustomJumbotron title='RUIDO LABORAL' color={"my-bg-red"} textColor={"text-white"} imagen={ruido}>
                        Estudio de ruido laboral NOM-011-STPS-2001.
                    </CustomJumbotron>
                </JumbotronContainer>
                <JumbotronContainer>
                    <CustomJumbotron title='RECIPIENTES O CALDERAS' color={"bg-light"} textColor={"text-white"} imagen={calderas}>
                        Recipientes sujetos a presión, recipientes criogénicos y generadores de vapor o
                        calderas NOM-020-STPS-2011 Unidad de verificación tipo C aprobado por la STPS.
                    </CustomJumbotron>
                    <CustomJumbotron title='ILUMINACIÓN' color={"my-bg-green"} textColor={"text-white"} imagen={iluminacion}>
                        Estudio de iluminación NOM-025-STPS-2008.
                    </CustomJumbotron>
                    <CustomJumbotron title='VIBRACIONES' color={"my-bg-yellow"} textColor={"text-white"} imagen={vibracion}>
                        Estudio de vibraciones NOM-024-STPS-2001.
                    </CustomJumbotron>
                </JumbotronContainer>
                <JumbotronMain title='ESTUDIOS PERIMETRALES Y ANÁLISIS CRIT' color={"my-bg-green"} textColor={"text-white"}>
                    <ul>
                        <li>Cumplimiento y mejora del desempeño ambiental de las actividades laborales cotidianas.</li>
                        <li>Desarrollan hábitos sustentables mediante un consumo responsable y eficiente de los materiales,
                            así como de otros bienes y servicios asociados a las actividades laborales.</li>
                        <li>Complementan las condiciones mínimas necesarias para la prevención a la contaminación.</li>
                    </ul>
                </JumbotronMain>
                <JumbotronContainer>
                    <CustomJumbotron title='RUIDO PERIMETRAL' color={"my-bg-red"} textColor={"text-white"} imagen={ruidoPerimetral}>
                        Estudio de ruido perimetral NOM-081-SEMARNAT-1994.
                    </CustomJumbotron>
                    <CustomJumbotron title='RESIDUOS PELIGROSOS' color={"bg-light"} imagen={residuos}>
                        Análisis de caracterización de residuos peligrosos (CRIT) NOM-052-SEMARNAT-2005.
                    </CustomJumbotron>
                </JumbotronContainer>
                <JumbotronMain title='ESTUDIOS DE FUENTES FIJAS Y SUELO' color={"my-bg-green"} textColor={"text-white"}>

                </JumbotronMain>
                <JumbotronContainer>
                    <CustomJumbotron title='EMISIONES A LA ATMÓSFERA' color={"bg-dark"} textColor={"text-white"} imagen={emisiones}>
                        Estudio de emisiones a la atmósfera por equipos de combustión y de partículas sólidas de fuentes fijas
                        NOM-085-SEMARNAT-2011 y NOM-043-SEMARNAT-1993.
                    </CustomJumbotron>
                    <CustomJumbotron title='ESTUDIO DE HIDROCARBUROS' color={"my-bg-red"} textColor={"text-white"} imagen={hidrocarburos}>
                        Estudio de hidrocarburos en suelo-abandonos de sitio NOM-138-SEMARNAT/SSA-2012.
                    </CustomJumbotron>
                </JumbotronContainer>
                <JumbotronMain title='ANÁLISIS DE AGUA RESIDUAL' color={"my-bg-yellow"} textColor={"text-white"}>

                </JumbotronMain>
                <JumbotronContainer>
                    <CustomJumbotron title='JMAS' color={"my-bg-red"} textColor={"text-white"} imagen={jmas}>
                        13 parámetros establecidos por la JMAS para cafeteria.
                    </CustomJumbotron>
                    <CustomJumbotron title='AGUA DE PROCESO' color={"my-bg-green"} textColor={"text-white"} imagen={aguaProcesos}>
                        Agua de proceso para cumplimiento con parámetros establecido por JMAS y con la NOM-002-SEMARNAT-1996.

                    </CustomJumbotron>
                    <CustomJumbotron title='AGUA DE REÚSO' color={"bg-dark"} textColor={"text-white"} imagen={aguaReuso}>
                        Agua de reúso para cumplimiento de NOM-003-SEMARNAT-1997.
                    </CustomJumbotron>
                </JumbotronContainer>
            </main>
        </>
    );
}
export default Studies;