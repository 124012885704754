import React from 'react';
import { useNavigate } from "react-router-dom";
import Carousel_trabajo from '../Home/Carousel_trabajos/Carousel';
import { HeroContainer } from '../Custom/Hero/HeroContainers';
import { HeroConBoton2, HeroImg } from '../Custom/Hero/HeroContent';
import Servicios from './Servicios/Servicios';
import quienes from '../../Imgs/Home/Quienes-somos.jpeg';


const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <Carousel_trabajo />
      <HeroContainer>
        <HeroImg imagen={quienes} alt={'¿QUIENES SOMOS?'} />
        <HeroConBoton2 title={'¿QUIÉNES SOMOS?'} onClick={() => navigate("/about")}>
          Somos una empresa dedicada a la solución de problemas en materia de riesgo y EH&S.
          <ul>
            <li>Nos basamos en la ingeniería aplicada y la mejora continua.</li>
            <li>Contamos con equipos multidisciplinarios capaces de otorgar la seguridad del cumplimiento legal y la prevención de riesgos.</li>
            <li>Garantizamos que las empresas cumplan con la normatividad tres ordenes de gobierno.</li>
          </ul>
        </HeroConBoton2>
      </HeroContainer>
      <Servicios />
    </>
  );
}
export default Home;