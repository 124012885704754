import React from 'react';

interface Props {
    children: React.ReactNode,
    id?:string
}


export const CartaContainer = ({ children,id }: Props ) => {
    return (
        <>
            <div className="my-2" id={id}>
                <div className="m-1 row py-2 px-4 pt-lg-8 align-items-center rounded-5 border shadow-lg">
                    {children}
                </div>
            </div>

        </>
    );
}

export const HeroContainer = ({ children,id }: Props) => {
    return (
        <>
            <div className="container-hero2 px-4 border-bottom" id={id}>
                <div className="container-content2 row flex-lg-row align-items-center">
                    {children}
                </div>
            </div>

        </>
    );
}

export const DiagonalContainer = ({ children,id }: Props) => {
    return (
        <>
            <div className="main-content overflow-hidden" id={id}>
                <div className="container-fluid col-xxl-8">
                    <div className="row flex-lg-nowrap align-items-center g-5">
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
}