
export class InputService{
    public static IsEmpty(val:string){
        if(val.trim() === ""){
            return true;
        }
        return false;
    }

    public static IsMail(val:string){
        const expression: RegExp = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
        let result: boolean = expression.test(val); 
        return !result;
    }
}