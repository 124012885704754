import React from 'react';
import Button from '../Button/Button';
import './ContentStyles.css';
interface ImgProps {
    rounded?: boolean,
    imagen: string,
    alt: string
}
interface BtnProps {
    title: string,
    children: React.ReactNode,
    onClick: () => void
}
interface Props {
    title: string,
    children: React.ReactNode
}
export const HeroImg = ({ imagen, alt, rounded }: ImgProps) => {
    return (
        <>
            <div className="heroImag col-lg-7 p-0 my-4">
                {rounded ?
                    <img className="w-100 rounded-3" src={imagen} alt={alt} width="720" />
                    :
                    <img className="w-100" src={imagen} alt={alt} width="720" />
                }
            </div>
        </>
    );
}
export const HeroCutImg = ({ imagen, alt }: ImgProps) => {
    return (
        <>
            <div className="order-lg-1 w-100">
                <img src={imagen} className="img-diagonal d-block mx-lg-auto img-fluid" alt={alt} />
            </div>
        </>
    );
}
export const HeroConBoton = ({ title, onClick, children }: BtnProps) => {
    return (
        <>
            <div className="hero-text col-lg-7 py-3 px-4">
                <h1 className="display-4 fw-bold lh-1">{title}</h1>
                <div className="lead">{children}</div>
                <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                        <Button texto='VER MÁS' onClick={onClick} />
                    </div>
                </div>
            </div>
        </>
    );
}

export const Hero = ({ title, children }: Props) => {
    return (
        <>
            <div className="hero-text col-lg-6 col-xl-5 text-center text-lg-start pt-lg-5 mt-xl-4">
                <h1 className="display-4 fw-bold lh-1">{title}</h1>
                <div className="lead">{children}</div>

            </div>
        </>
    );
}

export const HeroConBoton2 = ({ title, onClick, children }: BtnProps) => {
    return (
        <>
            <div className="hero-text col-lg-6 col-xl-5 text-center text-lg-start pt-lg-5 mt-xl-4">
                <h1 className="display-4 fw-bold lh-1">{title}</h1>
                <div className="lead">{children}
                    <Button texto='VER MÁS' onClick={onClick} />
                </div>
            </div>
        </>
    );
}

export const DiagonalConBoton = ({ title, children, onClick }: BtnProps) => {
    return (
        <>
            <div className="hero-text col-lg-6 col-xl-5 text-center text-lg-start pt-lg-5 mt-xl-4">
                <div className="lc-block mb-4">
                    <div >
                        <h1 className="fw-bold display-3">{title}</h1>
                    </div>
                </div>

                <div className="lc-block mb-5">
                    <div > {children}</div>
                </div>

                <div className="lc-block mb-6">
                    <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                        <Button texto='VER MÁS' onClick={onClick} />
                    </div>
                </div>
            </div>
        </>
    );
}