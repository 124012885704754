import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './Styles.css';
import Logo from '../../Imgs/Logos/LogoNoFondo.png'
interface Props {
    navStyle: 'home' | 'relative';

};

const NavBar = ({ navStyle }: Props) => {
    const navigate = useNavigate();
    const [nav, setNav] = useState<string>("transparent");

    useEffect(() => {
        if (navStyle === 'relative') {
            setNav(navStyle);
        }
        let computeProgress = () => {
            if (navStyle === 'home') {
                const scrolled = document.documentElement.scrollTop;
                const scrollLength = document.documentElement.scrollHeight -
                    document.documentElement.clientHeight;
                const progress = 100 * scrolled / scrollLength;
                { progress < 10 ? setNav("transparent") : setNav("") }
            } else {
                setNav(navStyle);
            }
        }

        window.addEventListener("scroll", computeProgress);
        return () => window.removeEventListener("scroll", computeProgress);
    });

    return (
        <>
            <nav className={`navbar navbar-expand-lg navbar-dark navbar-custom ${nav}`}>
                <div className="container">
                    <a className="navbar-brand" href='/'><img className='Logo' src={Logo} alt="logo" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"> <i className="fa-solid fa-bars-sort"></i></span>
                    </button>
                    <div className="collapse navbar-collapse " id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto ">
                            <li className="nav-item">
                                <a className="nav-link active menuItem" aria-current="page" href='/'>Inicio</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active menuItem" aria-current="page" onClick={() => navigate("/about")}>Quienes Somos?</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link active dropdown-toggle menuItem" id="navbarDropdown" role="button" aria-haspopup="true" data-bs-toggle="dropdown" aria-expanded="false">
                                    Servicios
                                </a>
                                <div className="dropdown-menu dropdown-multi-col" aria-labelledby="navbarDropdown">
                                    <div className="dropdown-col">
                                        <a className="dropdown-item disabled">Servicios</a>
                                        <a className="dropdown-item " onClick={() => navigate("/services/services#analysis")}>Análisis de riesgos.</a>
                                        <a className="dropdown-item " onClick={() => navigate("/services/services#audit")}>Auditoría</a>
                                        <a className="dropdown-item " onClick={() => navigate("/services/studies")}>Estudios en General.</a>
                                        <a className="dropdown-item " onClick={() => navigate("/services/services#control")}>Seguimiento y control.</a>
                                        <a className="dropdown-item " onClick={() => navigate("/services/services#contingencies")}>Plan de contigencias.</a>
                                    </div>
                                    <div className="dropdown-col">
                                        <a className="dropdown-item disabled">Asesorías</a>
                                        <a className="dropdown-item " onClick={() => navigate("/services/ehs")}>Cumplimiento legal <br /> en materia EH&S.</a>
                                        <a className="dropdown-item " onClick={() => navigate("/services/programs#procavech")}>Programa PROCAVECH.</a>
                                        <a className="dropdown-item " onClick={() => navigate("/services/programs#self-management")}>Programa de autogestión.</a>
                                    </div>
                                    <div className="dropdown-col">
                                        <a className="dropdown-item disabled">Capacitaciones</a>
                                        <a className="dropdown-item " onClick={() => navigate("/services/training#protection")}>Materia de protección civil.</a>
                                        <a className="dropdown-item " onClick={() => navigate("/services/training#montacargas")}>Manejo de montacargas.</a>
                                        <a className="dropdown-item " onClick={() => navigate("/services/training#waste")}>Residuos peligrosos <br /> y sustancias químicas.</a>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active menuItem" aria-current="page" onClick={() => navigate("/paperwork")}>Tramites</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active menuItem" aria-current="page" onClick={() => navigate("/contact")}>Contactanos</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}
export default NavBar;