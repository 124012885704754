import React from 'react';
import '../Contact/Styles.css';
import Form from './Form/Form';
import contacto from '../../Imgs/Contact/contactanos.png';


const Contact = () => {
    return (
        <>
            <div className='row mx-4'>
                <div className='col-md-6 col-sm-12' >
                    <img className='img-contact img-fluid' src={contacto} alt="" />
                </div>
                <div id='FormContacto' className='col-md-6 col-sm-12 p-3'>
                    <Form />
                </div>
            </div>
        </>
    );
}
export default Contact;