import React from 'react';
import './Styles.css';

interface Props {
    texto: string,
    submit?:boolean,
    onClick?: () => void
}


const Button = ({ texto, onClick ,submit }: Props) => {
    return (
        <>
            {submit ?
            <button type="submit" className="butn" onClick={onClick}>{texto}</button>
            :
            <button type="button" className="butn" onClick={onClick}>{texto}</button>
            }
            
        </>
    );
}
export default Button;