import { Routes, Route } from "react-router-dom";
import NavBar from '../Components/NavBar/NavBar';
import Footer from '../Components/Footer/Footer';
import Home from '../Components/Home/Home'
import About from '../Components/About/About';
import Contact from '../Components/Contact/Contact';
import Paperwork from '../Components/Paperwork/Tramites';

const Main = () => {
    return (
        <>
            <Routes>
                <Route index element={<>
                    <NavBar navStyle={"home"} />
                    <Home />
                    <Footer />
                </>} />
                <Route path='/about' element={<>
                    <NavBar navStyle={"relative"} />
                    <About />
                    <Footer />
                </>} />
                <Route path='/contact' element={<>
                    <NavBar navStyle={"relative"} />
                    <Contact />
                    <Footer />
                </>} />
                <Route path='/paperwork' element={<>
                    <NavBar navStyle={"relative"} />
                    <Paperwork />
                    <Footer />
                </>} />
            </Routes>
        </>
    );
}
export default Main;