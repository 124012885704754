import React from 'react';
import JumbotronMain from '../../../Custom/Jumbotron/Jumbotron-main';
import JumbotronContainer from '../../../Custom/Jumbotron/JumbotronContainer';
import { CustomJumbotron } from '../../../Custom/Jumbotron/JumbotronContent';
import desarrollo from '../../../../Imgs/Servicios/asesorias/EHS/desarrollo-humano.jpg';
import proteccion from '../../../../Imgs/Servicios/asesorias/EHS/proteccion.jpg';
import jmas from '../../../../Imgs/Servicios/asesorias/EHS/jmas.png';
import sdue from '../../../../Imgs/Servicios/asesorias/EHS/sdue.png';
import cofepris from '../../../../Imgs/Servicios/asesorias/EHS/cofepris.jpg';
import stps from '../../../../Imgs/Servicios/asesorias/EHS/stps.png';
import semarnat from '../../../../Imgs/Servicios/asesorias/EHS/semarnat.png';

const EHS = () => {
    return (
        <>
            <main>
                <JumbotronMain title='CUMPLIMIENTO LEGAL EN MATERIA EH&S.' color={"bg-dark"} textColor={"text-white"}>
                </JumbotronMain>
                <JumbotronContainer>
                    <CustomJumbotron title='DESARROLLO URBANO.' color={"my-bg-green"} textColor={"text-white"} imagen={desarrollo}>
                    </CustomJumbotron>
                    <CustomJumbotron title='PROTECCIÓN CIVIL.' color={"my-bg-yellow"} textColor={"text-white"} imagen={proteccion}>
                    </CustomJumbotron>
                </JumbotronContainer>
                <JumbotronContainer>
                    <CustomJumbotron title='JUNTA MUNICIPAL DE AGUA Y SANEAMIENTO (JMAS).' color={"my-bg-red"} textColor={"text-white"} imagen={jmas}>
                    </CustomJumbotron>
                    <CustomJumbotron title='SECRETARIA DE DESARROLLO URBANO Y ECOLOGÍA (SDUE).' color={"bg-dark"} textColor={"text-white"} imagen={sdue}>
                    </CustomJumbotron>
                </JumbotronContainer>
                <JumbotronContainer>
                    <CustomJumbotron title='COMISIÓN ESTATAL/FEDERAL PARA LA PROTECCIÓN CONTRA RIESGOS SANITARIOS (COESPRIS/COFEPRIS).' color={"my-bg-green"} textColor={"text-white"} imagen={cofepris}>
                    </CustomJumbotron>
                    <CustomJumbotron title='SECRETARÍA DEL TRABAJO Y PREVISIÓN SOCIAL (STPS).' color={"bg-light"} imagen={stps}>
                    </CustomJumbotron>
                    <CustomJumbotron title='SECRETARÍA DE MEDIO AMBIENTE Y RECURSOS NATURALES (SEMARNAT).' color={"my-bg-red"} textColor={"text-white"} imagen={semarnat}>
                    </CustomJumbotron>
                </JumbotronContainer>
            </main>
        </>
    );
}
export default EHS;