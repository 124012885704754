import React from 'react';
import './ContainerStyles.css';

interface Props {
    children: React.ReactNode;
}

const JumbotronContainer = ({ children }: Props) => {
    return (
        <>
            <div className="children-container">
                {children}
            </div>
        </>
    );
}
export default JumbotronContainer;