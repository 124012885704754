import React from 'react';
import JumbotronMain from '../Custom/Jumbotron/Jumbotron-main';
import JumbotronContainer from '../Custom/Jumbotron/JumbotronContainer';
import { CustomJumbotron } from '../Custom/Jumbotron/JumbotronContent';
import licencia from '../../Imgs/Tramites/licencia.png';
import planos from '../../Imgs/Tramites/planos.jpg';
import pipc from '../../Imgs/Tramites/pipc.jpg';
import impacto from '../../Imgs/Tramites/impacto-ambiental.jpg';
import residuos from '../../Imgs/Tramites/residuos.jpg';
import invResiduos from '../../Imgs/Tramites/inventario-residuos.png';
import emisiones from '../../Imgs/Tramites/emisiones.jpg';
import lau from '../../Imgs/Tramites/lau.png';
import residuosPeligrosos from '../../Imgs/Tramites/residuos-peligrosos.jpg';
import residuosPeligrosos2 from '../../Imgs/Tramites/residuos-peligrosos2.jpg';
import coa from '../../Imgs/Tramites/coa.jpg';
import abandono from '../../Imgs/Tramites/abandono.jpg';
import fase1 from '../../Imgs/Tramites/fase1.jpg';
import quimicas from '../../Imgs/Tramites/quimicas.jpg';
import aviso from '../../Imgs/Tramites/aviso.png';

const Paperwork = () => {
    return (
        <>
            <main>
                <JumbotronMain title='TRÁMITES/PERMISOS NIVEL MUNICIPAL' color={"bg-dark"} textColor={"text-white"}>
                </JumbotronMain>
                <JumbotronContainer>
                    <CustomJumbotron title="LICENCIA DE USO DE SUELO." color={"my-bg-green"} textColor={"text-white"} imagen={licencia}>
                    </CustomJumbotron>
                    <CustomJumbotron title={"ACTUALIZACIÓN DE PLANOS CATASTRALES."} color={"bg-light"} imagen={planos}>
                    </CustomJumbotron>
                    <CustomJumbotron title={"LICENCIAS DE FUNCIONAMIENTO EN BASE USO DE SUELO."} color={"my-bg-yellow"} textColor={"text-white"} imagen={licencia}>
                    </CustomJumbotron>
                </JumbotronContainer>
                <JumbotronContainer>
                    <CustomJumbotron title={"PROGRAMA INTERNO DE PROTECCIÓN CIVIL P.I.P.C."} color={"my-bg-red"} textColor={"text-white"} imagen={pipc}>
                    </CustomJumbotron>
                    <CustomJumbotron title={"REQUERIMIENTOS PARA PERMISO DE DESCARGA."} color={"bg-dark"} textColor={"text-white"} imagen={licencia}>
                    </CustomJumbotron>
                </JumbotronContainer>

                <JumbotronMain title='TRÁMITES/PERMISOS NIVEL ESTATAL' color={"my-bg-green"} textColor={"text-white"}>
                    SECRETARÍA DE DESARROLLO URBANO Y ECOLOGÍA.
                </JumbotronMain>
                <JumbotronContainer>
                    <CustomJumbotron title='ESTUDIO DE IMPACTO AMBIENTAL.' color={"bg-light"} imagen={impacto}>
                        <ul>
                            <li>Informe preventivo.</li>
                            <li>Manifiesto de impacto ambiental.</li>
                        </ul>
                    </CustomJumbotron>
                    <CustomJumbotron title='AUTORIZACIONES EN MATERIA DE RESIDUOS DE MANEJO ESPECIAL Y EMISIONES A LA ATMÓSFERA.' color={"my-bg-yellow"} textColor={"text-white"} imagen={residuos}>
                    </CustomJumbotron>
                </JumbotronContainer>
                <JumbotronContainer>
                    <CustomJumbotron title='FORMATO E INVENTARIO DE RESIDUOS Y EMISIONES (FRE).' color={"bg-dark"} textColor={"text-white"} imagen={invResiduos}>
                    </CustomJumbotron>
                    <CustomJumbotron title='ESTIMACIÓN DE EMISIONES MÉTODO EPA AP-42.' color={"bg-light"} imagen={emisiones}>
                    </CustomJumbotron>
                </JumbotronContainer>

                <JumbotronMain title='TRÁMITES/PERMISOS NIVEL FEDERAL' color={'my-bg-red'} textColor={"text-white"}>
                    SECRETARÍA DE MEDIO AMBIENTE Y RECURSOS NATURALES
                </JumbotronMain>
                <JumbotronContainer>
                    <CustomJumbotron title='LICENCIA AMBIENTAL ÚNICA (LAU).' color={"bg-light"} imagen={lau}>
                    </CustomJumbotron>
                    <CustomJumbotron title='REGISTRO/ACTUALIZACIÓN REGISTRO DE GENERADOR DE RESIDUOS PELIGROSOS' color={"my-bg-green"} textColor={"text-white"} imagen={residuosPeligrosos}>
                    </CustomJumbotron>
                    <CustomJumbotron title='REGISTRO/ACTUALIZACIÓN PLANES DE MANEJO DE RESIDUOS PELIGROSOS.' color={"bg-light"} imagen={residuosPeligrosos2}>
                    </CustomJumbotron>
                </JumbotronContainer>
                <JumbotronContainer>
                    <CustomJumbotron title='CÉDULA DE OPERACIÓN ANUAL (COA).' color={"my-bg-red"} textColor={"text-white"} imagen={coa}>
                    </CustomJumbotron>
                    <CustomJumbotron title='AVISO DE ABANDONO DE SITIO.' color={"bg-light"} imagen={abandono}>
                    </CustomJumbotron>
                    <CustomJumbotron title='EVALUACIÓN AMBIENTAL DE SITIO FASE 1.' color={"bg-dark"} textColor={"text-white"} imagen={fase1}>
                    </CustomJumbotron>
                </JumbotronContainer>

                <JumbotronMain title='TRÁMITES/PERMISOS GESTIÓN SANITARIA' color={'my-bg-yellow'} textColor={"text-white"}>
                </JumbotronMain>
                <JumbotronContainer>
                    <CustomJumbotron title='LICENCIAS SANITARIAS.' color={"bg-dark"} textColor={"text-white"} imagen={licencia}>
                    </CustomJumbotron>
                    <CustomJumbotron title='AVISO DE FUNCIONAMIENTO DE SUSTANCIAS QUÍMICAS.' color={"bg-light"} imagen={quimicas}>
                    </CustomJumbotron>
                    <CustomJumbotron title='AVISO DE RESPONSABLE SANITARIO DEL CENTRO DE TRABAJO.' color={"my-bg-green"} textColor={"text-white"} imagen={aviso}>
                    </CustomJumbotron>
                </JumbotronContainer>
                <JumbotronContainer>
                    <CustomJumbotron title='AVISO DE PUBLICIDAD.' color={"my-bg-yellow"} textColor={"text-white"} imagen={aviso}>
                    </CustomJumbotron>
                    <CustomJumbotron title='INFORME ANUAL DE PRECURSORES QUÍMICOS.' color={"my-bg-red"} textColor={"text-white"} imagen={quimicas}>
                    </CustomJumbotron>
                </JumbotronContainer>
            </main>
        </>
    );
}
export default Paperwork;