import React from 'react';
import './Styles.css';

interface Props {
    value?: string,
    name?:string,
    placeholder?: string,
    error? : boolean
    errorLog ? : string,
    onChange?: (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => void
}


export  const TextBox = ({ value,name,placeholder,error,errorLog, onChange }: Props) => {
    return (
        <>
        {error ?
            <div className='textbox-error '>
                <input className='textbox p-1' name={name} value={value} placeholder={placeholder} onChange={onChange}></input>    
            </div>
            :
            <div className='textbox-container '>
                <input className='textbox p-1' name={name} value={value} placeholder={placeholder} onChange={onChange}></input>
            </div>
        }
        {error ?
            <span className='span-error'>{errorLog}</span>
            :
            null
        }
            
        </>
    );
}

export  const TextArea = ({ value,name,placeholder,error,errorLog, onChange }: Props) => {
    return (
        <>
        {error ?
            <div className='textbox-error '>
                <textarea rows={5} className='textbox p-1' name={name} value={value} placeholder={placeholder} onChange={onChange}></textarea>    
            </div>
            :
            <div className='textbox-container '>
                <textarea rows={5} className='textbox p-1'  name={name} value={value} placeholder={placeholder} onChange={onChange}></textarea>
            </div>
        }
        {error ?
            <span className='span-error'>{errorLog}</span>
            :
            null
        }
            
        </>
    );
}
