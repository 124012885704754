import React from 'react';
import Button from '../Button/Button';
import './Styles.css';


interface Props {
    title: string,
    parrafo: string,
    imagen: string,
    onClick?: () => void
}


const Card = ({ title, parrafo, imagen, onClick }: Props) => {
    return (
        <>
            <div className="card">
                <div className="image-content">
                    <span className="overlay"></span>
                    <div className="card-image">
                        <img src={imagen} alt="" className="card-img" />
                    </div>
                </div>
                <div className="card-content">
                    <h2 className="name">{title}</h2>
                    <p className="description">{parrafo}</p>
                    <Button texto='VER MÁS'  onClick={onClick}/>
                </div>
            </div>
        </>
    );
}
export default Card;