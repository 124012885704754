import React from 'react';

interface Props {
    title: string,
    children: React.ReactNode,
    color?: "my-bg-green" | "bg-dark" | "my-bg-red" | "my-bg-yellow" | "bg-white" | "bg-light",
    textColor?: "text-white"
}

const JumbotronMain = ({ title, children, color, textColor }: Props) => {
    return (
        <>
            <div className={`position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center ${color} ${textColor}`}>
                <div className="col-md-5 p-lg-5 mx-auto my-5">
                    <h1 className="display-4 fw-normal">{title}</h1>
                    {children}
                </div>
                <div className="product-device shadow-sm d-none d-md-block"></div>
                <div className="product-device product-device-2 shadow-sm d-none d-md-block"></div>
            </div>
        </>
    );
}
export default JumbotronMain;