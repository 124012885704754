import { Routes, Route } from "react-router-dom";
import NavBar from '../Components/NavBar/NavBar';
import Footer from '../Components/Footer/Footer';
import Services from "../Components/Services/Services-section/Services";
import Studies from "../Components/Services/Services-section/Studies/Studies";
import EHS from '../Components/Services/Consultancies/EHS/EH&S';
import Programs from "../Components/Services/Consultancies/Programs/Programs";
import Training from "../Components/Services/Trainings/Training";

const ServicesRoutes = () => {
    return (
        <>
            <Routes>
                <Route path='/services/services' element={<>
                    <NavBar navStyle={"relative"} />
                    <Services />
                    <Footer />
                </>} />
                <Route path='/services/studies' element={<>
                    <NavBar navStyle={"relative"} />
                    <Studies />
                    <Footer />
                </>} />
                <Route path='/services/ehs' element={<>
                    <NavBar navStyle={"relative"} />
                    <EHS />
                    <Footer />
                </>} />
                <Route path='/services/programs' element={<>
                    <NavBar navStyle={"relative"} />
                    <Programs />
                    <Footer />
                </>} />
                <Route path='/services/training' element={<>
                    <NavBar navStyle={"relative"} />
                    <Training />
                    <Footer />
                </>} />
            </Routes>
        </>
    );
}
export default ServicesRoutes;