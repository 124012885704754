import React from 'react';
import './Style.css';

const Footer = () => {
    return (
        <>
            <div className="main-container my-0">
                <footer className="footer-container text-white text-center text-lg-start">
                    <div className="container p-4">
                        <div className="row mt-4">
                            <div className="about-container col-lg-4 col-md-12 mb-4 mb-md-0">
                                <h5 className="text-uppercase mb-4">ACERCA DE NOSOTROS</h5>

                                <p>
                                    Texto acerca de nosotros
                                </p>

                                <p>
                                    Más texto acerca de nosotros
                                </p>

                                <div className="mt-4">
                                    <a type="button" className="btn btn-floating btn-success btn-lg" target='_blank' href='https://www.facebook.com/igr.com.mx'><i className="fab fa-facebook-f"></i></a>
                                    <a type="button" className="btn btn-floating btn-success btn-lg"><i className="fab fa-dribbble"></i></a>
                                    <a type="button" className="btn btn-floating btn-success btn-lg"><i className="fab fa-twitter"></i></a>
                                    <a type="button" className="btn btn-floating btn-success btn-lg"><i className="fab fa-google-plus-g"></i></a>
                                </div>
                            </div>

                            <div className="inf-container col-lg-4 col-md-12 mb-4 mb-md-0">
                                <div className='title-container'>
                                    <h5 className="text-uppercase mb-4 pb-1">INFORMACIÓN</h5>
                                </div>
                                <div className='list-container justify-content-center'>
                                    <div className='fl-container'>
                                        <ul className="fa-ul">
                                            <li className="mb-3">
                                                <p>Ing. Raúl Méndez</p>
                                            </li>
                                            <li className="mb-3">
                                                <span className="fa-li"><i className="fas fa-envelope"></i></span><span className="ms-2">rmendez@igr.com.mx</span>
                                            </li>
                                            <li className="mb-3">
                                                <span className="fa-li"><i className="fas fa-phone"></i></span><span className="ms-2">656-708-15-76</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='sl-container'>
                                        <ul className="fa-ul">
                                            <li className="mb-3">
                                                <p>Ing. Isaí Tapia</p>
                                            </li>
                                            <li className="mb-3">
                                                <span className="fa-li"><i className="fas fa-envelope"></i></span><span className="ms-2">itapia@igr.com.mx</span>
                                            </li>
                                            <li className="mb-3">
                                                <span className="fa-li"><i className="fas fa-phone"></i></span><span className="ms-2">656-774-12-42</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="hrs-container col-lg-4 col-md-12 mb-4 mb-md-0">
                                <h5 className="text-uppercase text-center mb-4">HORARIO DE ATENCIÓN</h5>

                                <table className="table text-center text-white">
                                    <tbody className="font-weight-normal">
                                        <tr>
                                            <td>Lun - Vie:</td>
                                            <td>8am - 9pm</td>
                                        </tr>
                                        <tr>
                                            <td>Sabado:</td>
                                            <td>9am - 10pm</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="text-center p-3">
                        © 2022 Copyright:&nbsp;&nbsp; 
                        <a className="text-white" href="/">INGENIERÍA Y GESTIÓN DE RIESGO.</a>
                    </div>
                </footer>
            </div>
        </>
    );
}
export default Footer;