import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
//import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './Styles.css';
import analisis from '../../../Imgs/Carrusel/analisis-riego.jpeg';
import capacitacion from '../../../Imgs/Carrusel/capacitacion.jpeg';
import logos from '../../../Imgs/Carrusel/logos.png';
import img4 from '../../../Imgs/Carrusel/img4.jpg';
import img5 from '../../../Imgs/Carrusel/img5.jpg';
import img6 from '../../../Imgs/Carrusel/img6.jpg';
import img7 from '../../../Imgs/Carrusel/img7.jpg';
import img8 from '../../../Imgs/Carrusel/img8.jpg';


const Carousel = () => {
  return (
    <>
      <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          navigation
          loop={true}
          pagination={{ clickable: true, dynamicBullets: true }}
          autoplay={{ delay: 5000 }}
        >
          <SwiperSlide>
            <img src={analisis} className="d-block w-100 h-100" alt="banner 1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={capacitacion} className="d-block w-100 h-100" alt="banner 1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={logos} className="d-block w-100 h-100" alt="banner 1" />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
export default Carousel;