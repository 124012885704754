import React, { useState } from 'react';
import Button from '../../Custom/Button/Button';
import {TextBox , TextArea} from '../../Custom/TextBox/TextBox';
import { MailRequest } from '../../../Models/MailRequest';
import { InputService } from '../../../services/InputService';
import emailjs from '@emailjs/browser';

const Form = () => {
    const [mail,setMail] = useState<MailRequest>({nombre:"",email:"",telefono:"",mgs:""});
    const [nombre,setNombre] = useState<boolean>(false);
    const [email,setEmail] = useState<boolean>(false);
    const [tel,setTel] = useState<boolean>(false);
    const [mgs,setMgs] = useState<boolean>(false);


    const onSubmit = (e:React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault();
        let err = false;
        if(InputService.IsEmpty(mail.nombre)){
            setNombre(true);
            err=true;
        }
        if(InputService.IsEmpty(mail.telefono)){
            setTel(true);
            err=true;
        }
        if(InputService.IsEmpty(mail.mgs)){
            setMgs(true);
            err=true;
        }
        if(InputService.IsMail(mail.email)){
            setEmail(true);
            err=true;
        }

        if(!err){
            emailjs.sendForm('service_2cx5m68', 'template_ofj40vy', e.currentTarget, 'fF8C0fevbpuib3LB5')
                .then((result) => {
                    setMail({nombre:"",email:"",telefono:"",mgs:""});
                    window.alert("Enviado con Exito!!!");
                }, (error) => {
                    console.log(error.text);
                });
        }
    }


    return(
        <>
            <form className='d-flex flex-column' onSubmit={onSubmit} >
                <h1>Formulario de Contacto</h1>
                <div className='my-2'>
                    <label >Nombre:</label>
                    <TextBox
                        name={"nombre"}
                        value={mail.nombre}
                        error={nombre}
                        onChange={(e) => { setMail({ ...mail, nombre: e.currentTarget.value }); setNombre(false); }}
                        errorLog={"El Campo no puede estar vacio"} />
                </div>
                <div className='my-2'>
                    <label >E-Mail:</label>
                    <TextBox
                        name={"email"}
                        value={mail.email}
                        error={email}
                        onChange={(e) => { setMail({ ...mail, email: e.currentTarget.value }); setEmail(false); }}
                        errorLog={"Necesitamos un E-Mail Valido"} />
                </div>
                <div className='my-2'>
                    <label >Telefono:</label>
                    <TextBox
                        name={"telefono"}
                        value={mail.telefono}
                        error={tel}
                        onChange={(e) => { setMail({ ...mail, telefono: e.currentTarget.value }); setTel(false); }}
                        errorLog={"El Campo no puede estar vacio"} />
                </div>
                <div className='my-2'>
                    <label >Mensaje:</label>
                    <TextArea
                        name={"mgs"}
                        value={mail.mgs}
                        error={mgs}
                        onChange={(e) => { setMail({ ...mail, mgs: e.currentTarget.value }); setMgs(false); }}
                        errorLog={"El Campo no puede estar vacio"} />
                </div>
                <div className='my-1 d-flex justify-content-center'>
                    <Button texto='Enviar' submit />
                </div>
                
            </form>
        </>
    );
}
export default Form;