import React from 'react';
import './Styles.css'
import { HeroImg, Hero } from '../Custom/Hero/HeroContent';
import { CartaContainer } from '../Custom/Hero/HeroContainers';

import quienes from '../../Imgs/About/quienes.jpeg';
import mision from '../../Imgs/About/mision.jpg';
import vision from '../../Imgs/About/vision.png';
import fortaleza from '../../Imgs/About/fortaleza.jpg';
import trabajo from '../../Imgs/About/trabajo.jpg';
import eco from '../../Imgs/About/eco-ambientes.jpg'
import nashville from '../../Imgs/About/Nashville-Wire-Logo.jpg'
import tarima from '../../Imgs/About/tarima.jpg'

const About = () => {
    return (
        <>
            <div className="m-2 row p-4 pb-2 pt-lg-8 align-items-center rounded-5 border shadow-lg">
                <CartaContainer >
                    <Hero title={'¿QUIÉNES SOMOS?'}>
                        Somos una empresa dedicada a la solución de problemas en materia de riesgo y EH&S.
                        <ul>
                            <li>Nos basamos en la ingeniería aplicada y la mejora continua.</li>
                            <li>Contamos con equipos multidisciplinarios capaces de otorgar la seguridad del cumplimiento legal y la prevención de riesgos.</li>
                            <li>Garantizamos que las empresas cumplan con la normatividad tres ordenes de gobierno.</li>
                        </ul>
                    </Hero>
                    <HeroImg imagen={quienes} alt={'¿QUIENES SOMOS?'} rounded />
                </CartaContainer>
                <CartaContainer >
                    <HeroImg imagen={mision} alt={'MISIÓN?'} rounded />
                    <Hero title={'MISIÓN'}>
                        Aplicar nuestro conocimiento, experiencia y profesionalismo en la solución de problemas en materia de riesgo, medio ambiente y salud
                        mediante la ingeniería aplicada y la mejora continua. Enfocarnos en ir más allá del cumplimiento normativo consolidando una imagen
                        superior en el mercado para clientes y proveedores.
                    </Hero>
                </CartaContainer>
                <CartaContainer >
                    <Hero title={'VISIÓN'}>
                        Garantizar que las empresas cumplan con la reglamentación de seguridad y medio ambiente de los tres órdenes de gobierno, canalizando
                        beneficios como reducción del consumo de recursos, disminución de accidentes, daños materiales, instalaciones y personas, disminución
                        de pagos de seguros y prevención de pérdidas monetarias, multas o sanciones.
                    </Hero>
                    <HeroImg imagen={vision} alt={'VISIÓN'} rounded />
                </CartaContainer>
                <CartaContainer >
                    <HeroImg imagen={fortaleza} alt={'FORTALEZAS'} rounded />
                    <Hero title={'FORTALEZAS'}>
                        <ul>
                            <li>Tiempos de entrega y/o respuesta fiel a los descritos en cotización.</li>
                            <li>Profesionales en materia ambiental.</li>
                            <li>Autorización de prestador de servicio en materia de protección civil.</li>
                            <li>Autorización y/o no. de registro ante STPS para capacitaciones.</li>
                            <li>Acreditaciones EMA y aprobaciones ante STPS para laboratorio de pruebas físicas y fuentes fijas.</li>
                            <li>Aprobación como unidad de verificación tipo C ante STPS.</li>
                            <li>Aprobación ante PROFEPA de laboratorio en materia de emisiones y residuos peligrosos.</li>
                            <li>Equipos de medición con certificado de calibración no más de 1 año de vigencia.</li>
                        </ul>
                    </Hero>
                </CartaContainer>
                <CartaContainer >
                    <Hero title={'FORMA DE TRABAJO'}>
                        Nos ajustamos a las necesidades de nuestros clientes creando un lazo de compromiso y apoyo en la solución de los problemas existentes
                        <ul>
                            <li>Unidad de servicio de asesoría, trámite o estudio.</li>
                            <li>Planificación de iguala para asesoría y seguimiento del cumplimiento legal EH&S donde incluye:</li>
                            <ol>
                                <li>Trámites necesarios para la operación del proyecto o centro de trabajo.</li>
                                <li>Cronograma de trabajo y gestión de tabla de cumplimiento legal.</li>
                                <li>Asesoría al personal de EH&S de la planta para cumplir con las condicionantes derivadas de los permisos emitidos
                                    por las distintas dependencias gubernamentales.</li>
                                <li>Inspección continua para la verificación de cumplimientos de las condicionantes derivadas de los permisos
                                    emitidos por las distintas dependencias.</li>
                                <li>Asesoría y/o atención de inspecciones gubernamentales.</li>
                            </ol>
                        </ul>
                    </Hero>
                    <HeroImg imagen={trabajo} alt={'FORTALEZAS'} rounded />
                </CartaContainer>
            </div>
            <div className='references-container'>
                <div className='section1'>
                    <h1 className="fw-bold display-3">REFERENCIAS</h1>
                </div>
                <div className='section2 '>
                    <div className='img-reference-container'>
                        <img className='img-reference' src={eco} alt="" />
                    </div>
                    <div className='img-reference-container'>
                        <img className='img-reference' src={nashville} alt="" />
                    </div>
                    <div className='img-reference-container'>
                        <img className='img-reference' src={tarima} alt="" />
                    </div>
                </div>
            </div>
        </>
    );
}
export default About;