import React from 'react';
import './ContentStyles.css';

interface Props {
    title?: string,
    children: React.ReactNode,
    imagen?: string,
    color?: "my-bg-green" | "bg-dark" | "my-bg-red" | "my-bg-yellow" | "bg-white" | "bg-light",
    textColor?: "text-white"
}

export const CustomJumbotron = ({ title, children, color, imagen, textColor }: Props) => {
    return (
        <>
            <div className={`children-content ${color} d-flex flex-column justify-content-between text-center ${textColor} overflow-hidden`}>
                <div className="my-3 py-3">
                    <h2 className="display-5">{title}</h2>
                    {children}
                </div>
                <div className={`container-jumbotron shadow-sm mx-auto`} >
                    <img className='jumbotron-img' src={imagen} alt="" />
                </div>
            </div>
        </>
    );
}
export const Negro = ({ title, children }: Props) => {
    return (
        <>
            <div className="children-content bg-dark text-center text-white overflow-hidden">
                <div className="my-3 py-3">
                    <h2 className="display-5">{title}</h2>
                    {children}
                </div>
                <div className="container-jumbotron-img bg-light shadow-sm mx-auto" ></div>
            </div>
        </>
    );
}

export const Gris = ({ title, children }: Props) => {
    return (
        <>
            <div className="children-content bg-light text-center overflow-hidden">
                <div className="my-3 p-3">
                    <h2 className="display-5">{title}</h2>
                    <p className="lead">{children}</p>
                </div>
                <div className="container-jumbotron-img bg-dark shadow-sm mx-auto"></div>
            </div>
        </>
    );
}

export const Azul = ({ title, children }: Props) => {
    return (
        <>
            <div className="children-content bg-primary text-center text-white overflow-hidden">
                <div className="my-3 py-3">
                    <h2 className="display-5">{title}</h2>
                    <p className="lead">{children}</p>
                </div>
                <div className="container-jumbotron-img bg-light shadow-sm mx-auto"></div>
            </div>
        </>
    );
}

export const Verde = ({ title, children }: Props) => {
    return (
        <>
            <div className="children-content bg-success text-center text-white overflow-hidden">
                <div className="my-3 py-3">
                    <h2 className="display-5">{title}</h2>
                    <p className="lead">{children}</p>
                </div>
                <div className="container-jumbotron-img bg-light shadow-sm mx-auto"></div>
            </div>
        </>
    );
}

export const Blanco = ({ title, children }: Props) => {
    return (
        <>
            <div className="children-content bg-white text-center overflow-hidden">
                <div className="my-3 py-3">
                    <h2 className="display-5">{title}</h2>
                    <p className="lead">{children}</p>
                </div>
                <div className="container-jumbotron-img bg-light shadow-sm mx-auto"></div>
            </div>
        </>
    );
}

export const Transparente = ({ title, children }: Props) => {
    return (
        <>
            <div className="children-content bg-transparent text-center overflow-hidden">
                <div className="my-3 py-3">
                    <h2 className="display-5">{title}</h2>
                    <p className="lead">{children}</p>
                </div>
                <div className="container-jumbotron-img bg-light shadow-sm mx-auto"></div>
            </div>
        </>
    );
}