import React, { useEffect } from 'react';
import { CartaContainer } from '../../../Custom/Hero/HeroContainers';
import { HeroImg, Hero } from '../../../Custom/Hero/HeroContent';
import procavech from '../../../../Imgs/Servicios/asesorias/procavech.jpg';
import autogestion from '../../../../Imgs/Servicios/asesorias/autogestion.jpg';


const Programs = () => {
    useEffect(() => {
        const urlHash = window.location.hash;
        if (urlHash.length) {
            const element = document.getElementById(urlHash.substring(1));
            if (element) {
                element.scrollIntoView();
            }
        }
    }, []);
    return (
        <>
            <div className="m-2 row p-4 pb-2 pt-lg-8 align-items-center rounded-5 border shadow-lg">
                <CartaContainer id='procavech' >
                    <Hero title={'PROGRAMA PROCAVECH'}>
                        <p>Reconocimiento del Programa de Cumplimiento Ambiental Voluntario del Estado de Chihuahua (PROCAVECH). </p>
                        <p>Incentivar el cumplimiento de la normatividad ambiental de manera voluntaria en las empresas de jurisdicción estatal, así como a la
                            implementación de buenas prácticas ambientales.</p>
                    </Hero>
                    <HeroImg imagen={procavech} alt={'PROGRAMA PROCAVECH'} rounded />
                </CartaContainer>

                <CartaContainer id='self-management' >
                    <HeroImg imagen={autogestion} alt={'PROGRAMA DE AUTOGESTIÓN?'} rounded />
                    <Hero title={'PROGRAMA DE AUTOGESTIÓN'}>
                        <p>Promover que las empresas instauren y operen sistemas de administración en materia de seguridad y salud en el trabajo, con base a
                            estándares nacionales e internacionales, a fin de favorecer el funcionamiento de centros de trabajo seguros e higiénicos.</p>
                    </Hero>
                </CartaContainer>
            </div>
        </>
    );
}
export default Programs;